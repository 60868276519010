import { Applications as ApplicationsIcon } from '@griegconnect/krakentools-react-icons'
import { Button, Hidden, IconButton, Typography } from '@mui/material'
import { useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { leftDrawerAtom } from '../atoms/leftDrawerAtom'
import { useApplicationsQuery } from '../hooks'
import AppSwitcherDialog from './AppSwitcherDialog'
import AppSwitcherGrid from './AppSwitcherGrid'
import AppSwitcherPopper from './AppSwitcherPopper'

const AppSwitcher = () => {
  const [open, setOpen] = useState(false)
  const [popperAnchorEl, setPopperAnchorEl] = useState<null | HTMLDivElement | HTMLElement>(null)
  const buttonRef = useRef(null)
  const { data, isLoading, isError, refetch } = useApplicationsQuery()
  const handleClose = (_event: MouseEvent | TouchEvent | {}) => {
    setPopperAnchorEl(null)
    setOpen(false)
  }

  const handleOpen = (event: React.MouseEvent<HTMLDivElement | HTMLElement, MouseEvent>) => {
    setPopperAnchorEl(buttonRef!.current)
    setOpen(true)
  }

  const QueryErrorUi = (!isLoading || isError) && data === undefined && (
    <>
      <Typography color="error" variant="body2">
        Could not fetch applications. Try again?
      </Typography>
      <Button onClick={() => refetch()} variant="outlined" color="primary" size="small">
        Try again
      </Button>
    </>
  )

  return (
    <>
      <IconButton ref={buttonRef} onClick={handleOpen}>
        <ApplicationsIcon />
      </IconButton>
      <Hidden mdUp>
        <AppSwitcherDialog open={open} onClose={handleClose}>
          <AppSwitcherGrid applications={data}>{QueryErrorUi}</AppSwitcherGrid>
        </AppSwitcherDialog>
      </Hidden>
      <Hidden mdDown>
        <AppSwitcherPopper anchorEl={popperAnchorEl} onClickAway={handleClose}>
          <AppSwitcherGrid applications={data}>{QueryErrorUi}</AppSwitcherGrid>
        </AppSwitcherPopper>
      </Hidden>
    </>
  )
}

export default AppSwitcher
