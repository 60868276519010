import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Hidden from '@mui/material/Hidden'
import { Theme } from '@mui/material/styles'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import { TenantSelector } from './TenantSelector'
import { ProfileButton } from './ProfileButton'
import { NotificationsButton } from './NotificationsButton'
import Search from './Search'
import { LeftDrawerButton } from './LeftDrawerButton'
import { TopBarTitle } from './TopBarTitle'
import { useRecoilValue } from 'recoil'
import { userAtom } from '../atoms/userAtom'
import { headerAtom } from '../atoms/headerAtom'
import AppSwitcher from './AppSwitcher'
import ApplicationLogo from './ApplicationLogo'
import Stack from '@mui/material/Stack'
import { useApplicationOptions } from '../ApplicationOptionsContext'
import { headerTitleAtom } from '../atoms/headerTitleAtom'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      paddingTop: 'env(safe-area-inset-top)',
      paddingRight: 'env(safe-area-inset-right)',
      paddingLeft: 'env(safe-area-inset-left)',
      height: '100%',
      overflow: 'hidden',
      boxShadow: 'none',
    },
    toolbar: {
      height: '100%',
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    tools: {
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'end',
      '& > *': {
        marginLeft: theme.spacing(2),
      },
    },
  })
)

export type TopBarProps = {
  profileMenuChildren?: React.ReactNode
  /**
   * Only used to disable palette switch in order to default to a specific palette mode.
   * @deprecated
   */
  disablePaletteSwitch: boolean
}

export const TopBar = (props: TopBarProps) => {
  const { profileMenuChildren, disablePaletteSwitch } = props
  const classes = useStyles()
  const user = useRecoilValue(userAtom)
  const headerState = useRecoilValue(headerAtom)
  const { appBar } = useApplicationOptions()
  const titleState = useRecoilValue(headerTitleAtom)

  return (
    <AppBar position="static" className={classes.appBar} color="primary" enableColorOnDark={true}>
      <Toolbar className={classes.toolbar}>
        <Hidden mdUp>
          <LeftDrawerButton />
        </Hidden>
        <Stack flexGrow={1} spacing={1} direction="row">
          {appBar?.hideApplicationLogo !== true && <ApplicationLogo />}
          {titleState.component === undefined && appBar?.hideApplicationLogo === true && <TopBarTitle />}
          {titleState.component !== undefined && <>{titleState.component}</>}
        </Stack>
        <div className={classes.tools}>
          <Hidden smDown>
            {!headerState.hideSearch && <Search />}
            {!headerState.hideNotifications && <NotificationsButton />}
          </Hidden>
          <Hidden mdDown>{user && !headerState.hideTenantSelector && <TenantSelector />}</Hidden>
          {appBar?.hideApplicationSwitcher !== true && <AppSwitcher />}
          <ProfileButton profileMenuChildren={profileMenuChildren} disablePaletteSwitch={disablePaletteSwitch} />
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default TopBar
